<style lang="scss" scoped>
*{
    color: #fff;
}
.template.ybftest{
    padding: .2rem;
    input,textarea,select,option{
        color: #333;
        width: 100%;
        min-height: 20px;
    }
    textarea{
        height: 200px;
        resize: none;
    }
    button{
        width: 100px;
        background-color: #333;
        color: #fff;
        height: 50px;
    }
    p{
        word-break: break-all;
        word-wrap: break-word;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 50%;
            padding: 10px;
            box-sizing: border-box;
        }
    }
}
</style>
<template>
    <div class="template ybftest">
        <h1>易币付测试 - 充币请求</h1>
        <h2>请求接口地址:  {{ sendUrl }}</h2>
        <h3>请求参数:</h3>
        <hr>
        <ul>
                <li><label>amount: <input v-model="sendData.amount" type="text" placeholder="申请充值的金额,配合currency共同使用"></label></li>
                <!-- <li><label>return_url: <input v-model="sendData.return_url" type="text" placeholder="支付操作成功以后,回调商户上分接口的地址,如果配置错误会存在无法回调通知到账成功"></label></li> -->
                <li><label>card_holder_name: <input v-model="sendData.card_holder_name" type="text" placeholder="付款银行卡持卡人姓名"></label></li>
                <li>
                    <label>coin_type:
                        <select v-model="sendData.coin_type">
                             <!-- <option value="0">CoinTypeWithChain.BTC(type=0, symbol=btc, precision=8, byteLength=226)</option> -->
                             <!-- <option value="60">CoinTypeWithChain.ETH(type=60, symbol=eth, precision=18, byteLength=0)</option>
                             <option value="-60">CoinTypeWithChain.ERC20(type=-60, symbol=erc20, precision=18, byteLength=0)</option> -->
                             <!-- <option value="1">CoinTypeWithChain.USDT_OMNI(type=1, symbol=usdt-omni, precision=8, byteLength=403)</option> -->
                             <option value="2">CoinTypeWithChain.USDT_ERC20(type=2, symbol=usdt-erc20, precision=6, byteLength=0)</option>
                             <!-- <option value="3">CoinTypeWithChain.TRX(type=3, symbol=trx, precision=6, byteLength=0)</option> -->
                             <option value="4">CoinTypeWithChain.USDT_TRC20(type=4, symbol=usdt-trc20, precision=6, byteLength=0)</option>
                             <!-- <option value="-1">CoinTypeWithChain.UNKNOWN(type=-1, symbol=unknown, precision=6, byteLength=0)</option>
                             <option value="5">CoinTypeWithChain.EB(type=5, symbol=eb, precision=6, byteLength=0)</option>
                             <option value="6">CoinTypeWithChain.USDT_EB(type=6, symbol=USDC_EB, precision=2, byteLength=0)</option> -->
                        </select>
                    </label>
                </li>
                <li>
                    <label>depositOrderId: <input v-model="sendData.depositOrderId" type="text" placeholder="商户自己生成的唯一订单号不能用此Id进行幂等上分操作,如果重复发送相同的订单号第二次会报错">
                    </label>
                </li>
                <li>
                    <label>pay_type:
                        <select v-model="sendData.pay_type">
                            <!-- <option value="1">OTC(1,"OTC买币支付")</option> -->
                            <option value="2">PLATFORM(2,"易币付扫码转账支付");</option>
                       </select>
                    </label>
                </li>
                <li>
                    <label>payment_type:
                        <select v-model="sendData.payment_type">
                            <option value="1">BANK(1, "银行卡"),</option>
                       </select>
                    </label>
                </li>
                <li>
                    <label>remark:
                        <input v-model="sendData.remark" type="text" placeholder="备注信息, 仅在商户后台查询使用">
                    </label>
                </li>
                <li>
                    <label>telephone:
                        <input v-model="sendData.telephone" type="text" placeholder="用户的联系方式,一旦发生交易纠纷,使用此号码联系客户,如果错误的可能会导致资金无法到账">
                    </label>
                </li>
        </ul>
        <p>params:{{ sendData }}</p>
        <p>请求载荷：{{ requestString }}</p>
        <button @click="send">发送请求</button>
        <hr>
        <h2>请求返回：</h2>
        <textarea v-model="sendRespone"></textarea>
    </div>
</template>
<script setup>
import axios from "axios";
import { computed, onUnmounted, ref } from "vue";
import common from "../../utils/common";
// import ybf from "../../utils/ybf";
let sendUrl = ref(common.userApi.requestUrl+'/finance_api/ebpay/deposit');
let sendData = ref({
    amount: '10',
    card_holder_name:'',
    coin_type: '4',
    currency: '1', // 1=usdt; -1=CNY
    pay_type:'2',
    payment_type:'1',
    remark:'',
    // return_url:'https://www.baidu.com',
    telephone:'18888888888',
})

let pay_url = ref();
let sendRespone = ref('');
const send = ()=>{
    /* 
    充币请求 请求示例：{"merchantCode":"XXXX","merchantId":"xxxx","timestamp":null,"appType":null,"amount":"100","callBackUrl":"https://uat-aXXXXX","cardholderName":null,"currency":"1","coinType":"4","depositOrderId":"XXXXXXX","payType":"2","paymentType":"1","remark":null,"returnUrl":null,"telephone":"xxxxx","userCode":"50426","txMerchantId":null,"level":null,"memberCredit":null}

提币请求 请求示例：{"merchantCode":"xxxx","merchantId":"XXXXX","timestamp":"1678851788","appType":null,"amount":"100","callBackUrl":"https:/uXXXXX","currency":"1","remark":null,"userCode":"50426","walletAddress":"XXXXXXXXXXXXX","withdrawOrderId":"XXXXXXXXXX","withdrawQueryUrl":"https://axxxxx","callToken":"XXXXXX","memberName":null,"txMerchantId":null,"coinTypeWithChain":null}
    */
    try{
        axios.post(
            sendUrl.value,
            common.buildSendData(sendData.value)
            ).then((r)=>{
            sendRespone.value = JSON.stringify(r);
            if(r.data.code!=0){
                toastr["error"](r.data.msg+": "+r.data.ebpay_msg);
            }
            if(r.data.pay_url){
                pay_url.value = r.data.pay_url;
                common.showLoading('正在等候转账');
                window.open(r.data.pay_url);
                // location.replace(r.data.pay_url);
            }
        }).catch((e)=>{
            sendRespone.value = e;
        });
    } catch(e){
        sendRespone.value = e;
    }
}

window.addEventListener('error', (error) => {
    sendRespone.value = error;
}, true)

</script>